import React, { ReactElement } from "react";
import {
  Breadcrumbs,
  GatsbyPageProps,
  BannerHeader,
  InThisSection,
  InThisSectionLink,
  InThisSectionButton,
  FixedSharpImageFile,
} from "@ymcansw-camping/common";
import { graphql } from "gatsby";
import Layout from "../components/layout";

type VenueHireProps = GatsbyPageProps<{
  readonly bannerHeaderImage: FixedSharpImageFile;
}>;

function VenueHirePage({ data }: VenueHireProps): ReactElement<VenueHireProps> {
  return (
    <Layout
      meta={{
        title: "Venue Hire",
        description:
          "Camp Yarramundi is available to hire for social group gatherings, business training days, sports groups, family reunions, school fun days plus more.",
      }}
    >
      <BannerHeader title="Venue Hire" image={data.bannerHeaderImage} />
      <div className="page-content md-pl3 lg-pl6 group-accomodation-page">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-9 col-md-push-3">
              <div className="page-content-header">
                <div className="row">
                  <div className="col-xs-12 col-md-7 col-lg-8">
                    <Breadcrumbs items={["Venue Hire"]} />
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="content-block">
                  <h2>Hire a space today for any event.</h2>
                  <p>
                    Camp Yarramundi is available to hire for social group
                    gatherings, business training days, sports groups, family
                    reunions, school fun days plus more.
                  </p>
                  <p>
                    We can tailor a package to suit your budget and requirements
                    including menu options, self-catering, adventure activities,
                    accommodation and meeting room facilities.
                  </p>
                </div>

                <div className="content-block image-content-block">
                  <div className="row">
                    <div className="col-xs-12 col-sm-4">
                      <div className="image-container">
                        <img
                          className="img-fluid"
                          src={require("../images/img/venue-hire-image1.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                      <div className="image-container">
                        <img
                          className="img-fluid"
                          src={require("../images/img/venue-hire-image2.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                      <div className="image-container">
                        <img
                          className="img-fluid"
                          src={require("../images/img/venue-hire-image3.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-md-3 col-md-pull-9">
              <InThisSection>
                <InThisSectionLink to="/testimonials/">
                  Testimonials
                </InThisSectionLink>
                <InThisSectionLink to="/private-bookings/">
                  Private Bookings
                </InThisSectionLink>
                <InThisSectionButton to="/contact/">
                  Booking enquiry
                </InThisSectionButton>
              </InThisSection>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query VenueHireQuery {
    bannerHeaderImage: file(
      relativePath: { eq: "img/group-accommodation-bg.jpg" }
    ) {
      ...GeneralPageLayout_bannerHeaderImage
    }
  }
`;

export default VenueHirePage;
